import React from "react"

import styled from "@emotion/styled"

const ContactForm = () => {
  const FormContainer = styled.div`
    grid-column-end: span 6;

    @media (min-width: 46rem) {
      grid-column-end: span 3;
    }

    form {
      display: grid;
      grid-auto-flow: dense;
      grid-template-columns: repeat(4, 1fr);
      gap: 2rem;

      div {
        grid-column-end: span 4;

        label,
        input,
        textarea {
          background-color: transparent;
          display: block;
          width: 100%;
          outline: none;
          font-size: 1rem;
        }

        input,
        textarea {
          transition: border 0.33s ease, box-shadow 0.33s ease;
          border: 1px solid ${props => props.theme.grays.xlight};
          box-shadow: 0px 2px 0px 0px rgba(0, 0, 255, 0);
          resize: none;
          padding: 0.5rem;
          color: ${props => props.theme.grays.mid};

          &:active,
          &:focus {
            transition: border 0.33s ease, box-shadow 0.55s ease;
            border-color: ${props => props.theme.default.white};
            box-shadow: 0px 2px 0px 0px rgba(0, 0, 255, 1);
            color: ${props => props.theme.textColor};
          }
        }
      }

      button {
        border-radius: 2rem;
        padding: 0.75rem 1rem;
        text-align: center;
        border: 0;
        background-color: ${props => props.theme.primaryBlue};
        color: ${props => props.theme.default.white};
        cursor: pointer;
        display: block;
        outline: none;
        transition: all 0.22s ease;

        &:hover {
          transition: all 0.22s ease;
          color: ${props => props.theme.default.black};
          background-color: ${props => props.theme.primaryYellow};
        }
      }

      @media (min-width: 46rem) {
        grid-column-end: span 3;

        div {
          &.half {
            grid-column-end: span 2;
          }

          &.full {
            grid-column-end: span 4;
          }
        }
      }
    }
  `

  return (
    <FormContainer>
      <form
        name="contact"
        method="post"
        action="/thanks"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />

        <div className="half">
          <label htmlFor="name">Name</label>
          <input type="text" name="name" id="name" required />
        </div>

        <div className="half">
          <label htmlFor="organisation">Organisation</label>
          <input type="text" name="organisation" id="organisation" />
        </div>

        <div className="full">
          <label htmlFor="email">Email</label>
          <input type="text" name="email" id="email" required />
        </div>

        <div className="full">
          <label htmlFor="message">Message</label>
          <textarea name="message" id="message" required />
        </div>

        <div className="half">
          <label htmlFor="start-date">Preferred start date</label>
          <input type="text" name="start-date" id="start-date" />
        </div>

        <div className="half">
          <label htmlFor="launch-date">Launch Date</label>
          <input type="text" name="launch-date" id="launch-date" />
        </div>

        <div className="half">
          <button type="submit">Send Message</button>
        </div>
      </form>
    </FormContainer>
  )
}

export default ContactForm
