import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../components/seo/seo"
import Layout from "../components/layout"
import ContactForm from "../components/contact/contact-form"
import Availability from "../components/contact/availability"

const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactDetails {
      site {
        siteMetadata {
          siteUrl
        }
      }
      craft {
        globalSets(handle: ["contact", "social"]) {
          ... on Craft_contact_GlobalSet {
            contactEmail
            contactPhone
          }
          ... on Craft_social_GlobalSet {
            socialLinks {
              ... on Craft_socialLinks_network_BlockType {
                networkName
                networkUrl
              }
            }
          }
        }
        contactData: entries(section: "contact") {
          ... on Craft_contact_contact_Entry {
            seoTitle
            seoDescription
            image: seoImage {
              url(width: 720, height: 475, quality: 100, immediately: true)
            }
            imageFile {
              childImageSharp {
                fixed(width: 600, quality: 90) {
                  src
                }
              }
            }
            contactIntroduction
            contactBody
          }
        }
      }
    }
  `)

  const contactText = data.craft.contactData[0]

  const contactEmail = data.craft.globalSets[0].contactEmail
  const contactPhone = data.craft.globalSets[0].contactPhone
  const contactPhoneLink = contactPhone.replace(/ /g, "").replace("07", "+447")

  const links = data.craft.globalSets[1].socialLinks

  const seoTitle = contactText.seoTitle
  const seoDescription = contactText.seoDescription
  const seoImage =
    data.site.siteMetadata.siteUrl +
    contactText.imageFile[0].childImageSharp.fixed.src

  const ContactPageIntro = styled.section`
    padding-top: 6.5rem;
    padding-bottom: 1rem;
    margin-bottom: -4rem;
    max-width: 98rem;

    @media (min-width: 46rem) {
      padding-top: 8.5rem;
      padding-bottom: 0;
    }
  `

  const ContactContainer = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    padding-top: 6rem;
    margin-bottom: 4rem;
    gap: 2rem;

    @media (min-width: 46rem) {
      padding-top: 8.5rem;
      gap: 7rem;
    }

    h1 {
      grid-column-end: span 6;
    }
  `

  const ContactContent = styled.div`
    grid-column-end: span 6;

    @media (min-width: 46rem) {
      grid-column-end: span 3;
    }
  `

  const ContactList = styled.ul`
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0;
      padding: 0;

      &:before {
        content: "—";
        margin-right: 0.55rem;
      }

      a {
        color: inherit;
        font-weight: 600;
        text-decoration: none;
      }
    }
  `

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        pathname={`contact`}
        website
      />

      <ContactPageIntro>
        <h1 className="pageTitle">{contactText.contactIntroduction}</h1>
      </ContactPageIntro>

      <ContactContainer>
        <ContactContent>
          <div dangerouslySetInnerHTML={{ __html: contactText.contactBody }} />

          <ContactList>
            <li>
              <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
            </li>
            <li>
              <a href={`tel:${contactPhoneLink}`}>{contactPhone}</a>
            </li>
            <li>
              <a href={links[0].networkUrl} title={links[0].networkName}>
                {links[0].networkName}
              </a>
            </li>
          </ContactList>

          <Availability />
        </ContactContent>
        <ContactForm />
      </ContactContainer>
    </Layout>
  )
}

export default ContactPage
